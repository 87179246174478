import LegalLayout from "../../../components/legal/LegalLayout"
import React from "react"
import source from "raw-loader!../../../constants/legal/privacy/index.md"

export default function PrivacyPage() {
  return (
    <LegalLayout
      description="The privacy of your data — and it is your data, not ours! — is a big deal to us. Here's the rundown of what we collect and why, when we access your information, and your rights."
      source={source}
      title="Privacy Policy"
    />
  )
}
